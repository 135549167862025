<template>
    <div>
        <button @click="addEmptyRow">
            <slot name="button">
                <i class="iconfont iconadd-circle"></i>
            </slot>
        </button>
    </div>
</template>
  
<script>
export default {
    methods: {
        addEmptyRow() {
            this.$emit("add-row");
        }
    }
};
</script>
  
<style scoped>
/* 样式可以根据需要进行自定义 */
i {
    background-color: #F8F9FB;
}
</style>
  